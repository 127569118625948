import React, {useEffect, useRef, useState} from 'react';
import {Dropdown, FormControl} from 'react-bootstrap';
import moment from 'moment';

interface SearchSelectProps {
  sortedCurrentDic: any[];
  selectedValue: string;
  onValueChange: (value: string) => void;
}

const SearchSelect: React.FC<SearchSelectProps> = ({
                                                     sortedCurrentDic,
                                                     selectedValue,
                                                     onValueChange,
                                                   }) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const filteredVacancies = sortedCurrentDic.filter((vacancy) =>
    vacancy.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (value: string) => {
    onValueChange(value);
    setSearch('');
    setIsOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedValue) {
      onValueChange('');
    }
    setSearch(e.target.value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedVacancy = sortedCurrentDic.find((vac) => vac.id === selectedValue);
  const valueWithDate = selectedVacancy
    ? `${selectedVacancy.name} ${selectedVacancy.archived_at ? `(${moment(selectedVacancy.archived_at).format('DD.MM.YYYY')})` : ''}`
    : search;

  return (
    <div className="position-relative" ref={containerRef}>
      <FormControl
        placeholder="Выберите вакансию..."
        value={valueWithDate}
        onClick={() => setIsOpen(!isOpen)}
        onChange={handleInputChange}
        className="form-select w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {isOpen && (
        <Dropdown.Menu
          show
          className="w-full position-absolute z-10 mt-1 bg-white border rounded shadow"
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
          }}
        >
          {filteredVacancies.map((vacancy) => (
            <Dropdown.Item
              key={vacancy.id}
              onClick={() => handleSelect(vacancy.id)}
            >
              <div>
                <strong>{vacancy.name}</strong>
              </div>
              <div className="text-muted text-sm">
                {vacancy.archived_at && moment(vacancy.archived_at).format('DD.MM.YYYY')}
              </div>
            </Dropdown.Item>
          ))}
          {filteredVacancies.length === 0 && (
            <div className="p-2 text-muted text-sm">Нет совпадений</div>
          )}
        </Dropdown.Menu>
      )}
    </div>
  );
};

export default SearchSelect;
